<template>
  <div class="header">
    <div class="e-width flex flex-y-center flex-x-between">
      <div class="logo flex cp flex-y-center" @click="$router.push({ name: 'home' })">
        <img src="/img/logo.png" width="160px" />
        <div class="fs-22 pd-l-20 fwb">中国银联分期合作服务商</div>
      </div>
      <div class="nav flex flex-y-center fs-16">
        <div class="item" :class="{ active: activeName == item.id }" v-for="(item, key) in navList" :key="key"
          @click="toPage(item.id)">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    activeName() {
      return this.$route.name;
    },
  },
  data() {
    return {
      navList: [
        {
          id: "home",
          name: "助力商户",
        },
        {
          id: "products",
          name: "产品中心",
        },
        {
          id: "about",
          name: "关于我们",
        },
        {
          id: "leaveOnline",
          name: "在线留言",
        },
        {
          id: "merchantAdmin",
          name: "商户后台",
        },
      ],
    };
  },
  methods: {
    toPage(name) {
      if (name == "merchantAdmin") {
        window.open("https://ruyigou.zjdt.cc:8030");
        return;
      }
      this.$router.push({ name: name });
    },
  },
};
</script>

<style lang="scss">
.header {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
  background: #fff;
  // border-bottom: 2px solid #dd2c34;
  &:after {
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 0;
    right: 0px;
    height: 2px;
    width: calc((100vw - 1098px) / 2 + 500px);
    content: "";
    // -webkit-transform: scaleY(0.5);
    // -ms-transform: scaleY(0.5);
    // transform: scaleY(0.5);
    background-color: #dd2c34;
  }
  .nav {
    .item {
      width: 100px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s;
      &.active {
        background: #dd2c34;
        color: #fff;
      }
      &:hover {
        background: #dd2c34;
        color: #fff;
      }
    }
  }
}
</style>