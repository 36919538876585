import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import '@/assets/stlyle/element-variables.scss'
Vue.use(ElementUI);
Vue.config.productionTip = false;

import "@/assets/stlyle/normalize.css";
import "@/assets/stlyle/comm.scss";
import "@/assets/stlyle/flex.css";
import "@/assets/stlyle/help.scss";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
