<template>
  <div id="app">
    <Header />
    <div class="bg-fff" style="min-height:calc(100vh - 60px - 140px)">
      <router-view />
    </div>
    <Footer />
    <!-- <div class="gotop w-60">
      <div class="w-60 h-78 item p-rel" @click="open('https://ruyigou.zjdt.cc:8030')">
        <img src="/img/nav_glht.png" class="icon w-46 h-46 p-abs" />
        <img src="/img/nav_glht_a.png" class="icon-a w-46 h-46 p-abs" />
        <div class="fs-12 top-56 p-abs w-60 tac">商户后台</div>
      </div>
      <div class="flex w-60 h-60 item p-rel" @click="toTop">
        <img src="/img/nav_bt.png" class="icon w-46 h-46 p-abs" />
        <img src="/img/nav_bt_a.png" class="icon-a w-46 h-46 p-abs" />
      </div>
    </div>-->
  </div>
</template>
<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
export default {
  components: { Footer, Header },
  methods: {
    open(url) {
      window.open(url);
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
  },
};
</script>
<style lang="scss" scoped>
.gotop {
  position: fixed;
  right: 80px;
  bottom: 050px;
  cursor: pointer;
  .item {
    transition: all 0.3s;
    position: relative;
    border-radius: 8px;
    img {
      left: 50%;
      top: 10px;
      transform: translateX(-50%);
    }
    &:hover {
      background: #dd2c34;
      color: #fff;
      .icon-a {
        opacity: 1;
        z-index: 100;
      }
      .icon {
        opacity: 0;
      }
    }
    .icon-a {
      opacity: 0;
    }
  }
}
</style>

